<template>
    <div>
      <NavBar
        style="max-height: 50px;margin-bottom: 10px;"
        :username="currentUserName"
        :pageName="pageName"
      ></NavBar>
  
      <v-app>
        <div style="background-color: #f5faff; height: 93vh !important;">
          <v-row cols="12">
            <v-col cols="2">
              <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
            </v-col>
  
            <v-col cols="10" style="overflow:scroll; height:90vh;">
              <div style="margin: 10px 20px 20px 50px">
                <div style="display: flex; flex-direction:row; justify-content: space-between;">
                  <div style="width:30%;" align="left">
                    <span style="font-weight: 800; font-size: 12pt;padding-left: 8px; text-transform: uppercase; letter-spacing: 1px;">
                       <h3>Service Doctor Management</h3>
                    </span>
                  </div>
  
                  <div style="display:flex;">
                    <div >
                      <span
                        style="font-size: 26pt;
                        display: flex;
                        justify-content: space-between;
                        align-items: end;"
                        >
                        <v-btn
                          append
                          rounded
                          color="primary"
                          style="height:35px; margin-top:3vh;"
                          @click="addServiceDoctorFunction"
                          >
  
                          <span style="font-size: 18pt;"> + </span>
                          <span style="font-weight: 600;">Add Service Doctor</span>
                        </v-btn>
                      </span>
                    </div>
                  </div>
                </div>
  
                <div v-if="!isLoading" style="margin-top: 2%; height: 500px; margin-top: 3%;">
                  <table class="tableClass"  v-if="serviceDoctorData.length > 0">
                    <thead>
                      <tr>
                        <th class="text-left">Service Doctor Name</th>
                        <th class="text-left">Service Doctor SAMA ID</th>
                        <th class="text-left">Specialization</th>
                        <th class="text-left">Position</th>
                        <th class="text-left">Created By</th>
                        <th class="text-left">Availability Status</th>
                        <th class="text-left">Actions</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      <tr v-for="serviceDoctor in serviceDoctorData" :key="serviceDoctor._id">
                        <td style="padding: 5px;"  align="left">
                          {{ serviceDoctor.doctor_name }}
                        </td>
                        <td style="padding: 5px;"  align="left">
                          {{ serviceDoctor.doctor_id }}
                        </td>
                        <td style="padding: 5px;"  align="left">
                          {{ serviceDoctor.specialization_name }}
                        </td>
                        <td style="padding: 5px;"  align="left">
                          {{ serviceDoctor.position }}
                        </td>
                        <td style="padding: 5px;" align="left">
                          {{ serviceDoctor.created_by }}
                        </td>
                        <td>
                          <v-switch
                              color = "primary"
                              :loading = "isEditLoading"
                              :disabled = "isDisabled"
                              dense
                              v-model=serviceDoctor.availability_status
                              @change="changeAvailabilityStatus(serviceDoctor._id,serviceDoctor.availability_status)"
                          ></v-switch>
                        </td>
                        <td align="left">
                          <div  style="display:flex; flex-direction:row;">
                            <a style="margin:5px;padding-right:5px"
                              class="btnClass"
                              depressed
                              icon
                              @click="openEditModel(serviceDoctor._id,serviceDoctor.doctor_name, serviceDoctor.position)"
                              >
                              <img
                              src="https://s3iconimages.mymedicine.com.mm/edit.svg"
                              />
                            </a>
                            <a style="margin:5px;padding-right:5px"
                              class="btnClass"
                              depressed
                              icon
                              @click="openConfirmDialog(serviceDoctor._id)"
                              >
                              <img
                              src="https://s3iconimages.mymedicine.com.mm/delete.svg"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else>
                    <p>
                      No Service Doctor Found !
                    </p>
                  </div>
                  <div v-if="pagination_list > 1">
                    <v-pagination
                      circle
                      v-model="page"
                      :length="pagination_list"
                      :total-visible="7"
                      @input="findPage(page)"
                    ></v-pagination>
                  </div>
                </div>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-else
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
        </div>
  
        <div>
          <v-row justify="center">
            <v-dialog v-model="deleteSeviceDoctorModel" persistent max-width="350" :loading="isDeleteDisabled">
              <v-card>
                <v-card-title style="word-break:normal">
                  Do you want to delete the Service Doctor?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed
                    color="primary" @click="deleteServiceDoctor" :disabled = "isDeleteDisabled">
                    Yes
                  </v-btn>
                  <v-btn depressed
                    color="primary" @click="closeConfirmDialog" :disabled = "isDeleteDisabled">
                    No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
  
        <div>
          <v-row justify="center">
            <v-dialog v-model="editModal" persistent max-width="1000">
              <v-card :loading="isEditLoading">
                <v-card-title style="word-break:normal">
                  Edit Service Doctor Position
                </v-card-title>
                <v-card-text>
                  <div class="rowFlex">
                    <div class="colFlex">
                      <div style="width: 50%;">
                        Doctor Name
                      </div>
                      <div style="width: 50%;">
                        {{selected_doctor_name}}
                        </v-text-field>
                      </div>
                    </div>
                    <div class="colFlex">
                      <div style="width: 50%;">
                        Doctor Position
                      </div>
                      <div style="width: 50%;">
                        <v-text-field type="number" @wheel="$event.target.blur()" v-model="selected_doctor_position" min=0 :rules="positionRules" placeholder="Enter doctor position">
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="editServiceDoctor" :disabled="isEditLoading || !( selected_doctor_position && selected_doctor_position >= 0 && selected_doctor_position != selected_doctor_old_position )">
                    Update
                  </v-btn>
                  <v-btn depressed color="primary" @click="closeServiceDocModel">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
  
        <div>
          <v-row justify="center">
            <v-dialog v-model="addModal" persistent max-width="1000">
              <v-card :loading="isEditLoading">
                <v-card-title style="word-break:normal">
                  Add Service Doctor
                </v-card-title>
                <v-card-text>
                  <div class="rowFlex">
                    <div class="colFlex">
                      <div style="width: 50%;">
                        Specialization
                      </div>
                      <div style="width: 50%;">
                        <v-select
                          v-model="selected_specialization"
                          solo
                          label="Specialization"
                          :items = "specialization_list"
                          item-text = "specialization_name"
                          item-value = "_id"
                          return-object
                          @change = 'getDoctorList'
                          >
                        </v-select>
                      </div>
                    </div>
                    <div class="colFlex">
                      <div style="width: 50%;">
                        Doctors
                      </div>
                      <div style="width: 50%;" class="rowFlex">
                        <div>
                          <v-select
                            v-model="selected_doctor"
                            solo
                            label="Doctor"
                            :items = "doctors_list"
                            item-text = "doctor_name"
                            item-value = "_id"
                            return-object
                            :disabled="!selected_specialization"
                            @change = 'SelectDoctor'
                            >
                          </v-select>
                        </div>
                      </div>
                    </div>
                    <div class="colFlex">
                      <div style="width: 50%;">
                        Doctor Position
                      </div>
                      <div style="width: 50%;">
                        <v-text-field type="number" @wheel="$event.target.blur()" v-model="position" min=0 :rules="positionRules" placeholder="Enter doctor position">
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="addServiceDoctor" :disabled="isButtonClicked || !(selected_doctor != null && ( position && position >= 0 ))">
                    Add
                  </v-btn>
                  <v-btn depressed
                    color="primary" @click="closeAddModal">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
      </v-app>
    </div>
  </template>
  
  
  <script>
  import axios from "axios";
  import NavBar from "../../../views/navbar.vue";
  import SideBar from "../../../views/sideBar.vue";
  import { axios_auth_instance_admin } from '../../../utils/axios_utils';
  import { handleError } from '../../../utils/utils';
  
  export default {
    name: "ServiceDoctorDashboard",
    data() {
      return {
        serviceDoctorData: [],
        user_id: "",
        currentUser: "",
        currentUserName: "",
        pageName: "",
        deleteSeviceDoctorModel: false,
        selectedServiceDoctor : "",
        isLoading : true,
        pagination_list: 0,
        page:1,
        isEditLoading: false,
        testCondition:true,
        isDisabled: false,
        editModal: false,
        addModal: false,
        titleRules: [ (v) => !!v || 'Text is required', (v) => v.length < 160 || 'Cannot exceed 160 characters' ],
        positionRules: [(v) => !!v || 'Position is required', (v) => v >= 0 || 'Cannot be negative' ],
        short_description: '',
        short_description_mm: '',
        uploadedEnFile: null,
        fileTypes:["image/jpeg","image/png"],
        attachmentFiles: null,
        isButtonClicked: false,
        isChangeLoading: false,
        isDeleteDisabled: false,
        specialization_list: [],
        selected_specialization: null,
        doctors_list: [],
        selected_doctor_list: [],
        selected_doctor: null,
        imageUrl: "",
        position: null,
        selected_doctor_name: "",
        selected_doctor_position: null,
        selected_doctor_id: null,
        selected_doctor_old_position: null
      };
    },
    mounted() {
      this.pageName = "adminServiceDoctorDashboard";
      this.currentUser = this.$cookies.get("token");
  
      if (!this.currentUser) {
        this.$router.push({
          name: "AdminLogin",
        });
      } else {
        let readAllServiceDoctorsRequest = {
          token : this.currentUser,
          typeOfUser : "ADMIN"
        };
        this.isLoading = true;
        //mounted pagewise API call.
        axios_auth_instance_admin.post("/readAllServiceDoctors", readAllServiceDoctorsRequest)
        .then((readAllServiceDoctorsResponse) => {
          this.serviceDoctorData = readAllServiceDoctorsResponse.data.data;
          this.serviceDoctorData.forEach((x) => {
            x.availability_status = x.availability_status == 'AVAILABLE';
          });
          this.specialization_list = readAllServiceDoctorsResponse.data.specializationData;
          this.isLoading = false;
        })
        .catch((readAllServiceDoctorsError) => {
          handleError(readAllServiceDoctorsError, this.$router, 'readAllServiceDoctorsError', 'AdminLogin', 'token');
        });
      }
    },
    components: {
      NavBar,
      SideBar
    },
    methods: {
      closeServiceDocModel()
      {
        this.editModal = false;
      },
      openEditModel(doctor_id, doctor_name, position)
      {
        this.editModal = true;
        this.selected_doctor_name = doctor_name;
        this.selected_doctor_id = doctor_id;
        this.selected_doctor_position = position;
        this.selected_doctor_old_position = position;
      },
      getDoctorList() {
        this.isEditLoading = true;
        this.doctors_list = [];
        this.selected_doctor = null;
        let getDoctorsBySpecializationRequest = {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          specialization: this.selected_specialization._id,
        };
        axios.post(
          process.env.VUE_APP_BACKEND_URL + "/getDoctorsBySpecialization", getDoctorsBySpecializationRequest)
        .then((getDoctorsBySpecializationResponse) => {
          this.doctors_list = getDoctorsBySpecializationResponse.data.data;
          this.isEditLoading = false;
        })
        .catch((getDoctorsBySpecializationError) => {
          window.alert("Something went wrong");
          console.log(getDoctorsBySpecializationError);
          this.isEditLoading = false;
        });
      },
      SelectDoctor() {
        this.selected_doctor_list = this.selected_doctor;
      },
      getPics() {
        this.attachmentFiles = null;
        if(this.uploadedEnFile)
        {
          if(this.fileTypes.includes(this.uploadedEnFile.type))
          {
            let reader = new FileReader();
            reader.readAsDataURL(this.uploadedEnFile);
            reader.onload = function(e) {
              // Make a fileInfo Object.
              let img = new Image();
              img.src = e.target.result;
              img.onload = function (x) {
                if( img.width == 1029 && img.height == 335 )
                {
                  let fileInfo = {
                    name: this.uploadedEnFile.name,
                    type: this.uploadedEnFile.type,
                    size: Math.round(this.uploadedEnFile.size / 1000)+' kB',
                    base64: reader.result,
                    file: this.uploadedEnFile
                  };
                  this.attachmentFiles = fileInfo;
                  this.imageUrl = URL.createObjectURL(this.uploadedEnFile);
                }
                else
                {
                  window.alert("Please upload the image with 1029 x 335 dimension");
                  console.log("Provided dimension: ", this.width, " x ", this.height);
                  this.uploadedEnFile = null;
                  this.attachmentFiles = null;
                }
              }.bind(this);
            }.bind(this);
          } else {
            this.uploadedEnFile = null;
            this.attachmentFiles = null;
            window.alert("Not a valid file");
          }
        }
      },
      editServiceDoctor() {
        //api call to update.
        this.isEditLoading = true;
        let editServiceDoctorBody = {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          serviceDoctorId: this.selected_doctor_id,
          position: this.selected_doctor_position
        };
        axios_auth_instance_admin.post("/editServiceDoctorPosition", editServiceDoctorBody)
        .then(() => {
          window.alert("Edited");
          this.$router.go();
        })
        .catch((editServiceDoctorError) => {
          this.isEditLoading = false;
          handleError(editServiceDoctorError, this.$router, 'editServiceDoctorError', 'AdminLogin', 'token');
        });
      },
      openConfirmDialog(specialization_id) {
        this.selectedServiceDoctor = specialization_id;
        this.deleteSeviceDoctorModel = true;
      },
      closeConfirmDialog() {
        this.selectedServiceDoctor = null;
        this.deleteSeviceDoctorModel = false;
      },
      addServiceDoctorFunction() {
        this.addModal = true;
        this.selected_specialization = null;
        this.selected_doctor_list = [];
        this.selected_doctor = null;
        this.selected_doctor_position =  null;
      },
      findPage(pageNum) {
        this.isLoading = true;
        let adminViewSpecializationPageRequest = {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          page: pageNum,
          limit: 10,
        };
        axios_auth_instance_admin.post("/adminViewSpecializationPage", adminViewSpecializationPageRequest)
        .then((adminViewSpecializationPageResponse) => {
          this.serviceDoctorData = adminViewSpecializationPageResponse.data.specialization_data.docs;
          this.pagination_list = adminViewSpecializationPageResponse.data.specialization_data.totalPages;
          this.isLoading = false;
        })
        .catch((adminViewSpecializationPageError) => {
          handleError(adminViewSpecializationPageError, this.$router, 'adminViewSpecializationPageError', 'AdminLogin', 'token');
        });
      },
      addServiceDoctor()
      {
        this.isButtonClicked = true;
          let addServiceDoctorRequest = {
            doctorId: this.selected_doctor._id,
            position: this.position,
            token: this.currentUser,
            typeOfUser: "ADMIN"
          };
          axios_auth_instance_admin.post("/addServiceDoctor", addServiceDoctorRequest)
          .then(() => {
            window.alert(" Added Successfully ");
            this.uploadedEnFile = null;
            this.attachmentFiles = null;
            this.short_description = "";
            this.short_description_mm = "";
            this.position = null;
            this.isButtonClicked = false;
            this.addModal = false;
            this.$router.go();
          }).catch((addServiceDoctorError) => {
            this.isButtonClicked = false;
            handleError(addServiceDoctorError, this.$router, 'addServiceDoctorError', 'AdminLogin', 'token');
          });
      },
      closeAddModal() {
        this.addModal = false;
      },
      deleteServiceDoctor() {
        this.isDeleteDisabled = true;
        let deleteServiceDoctorRequest = {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          serviceDoctorId: this.selectedServiceDoctor
        };
        axios_auth_instance_admin.post("/deleteServiceDoctor", deleteServiceDoctorRequest)
        .then(() => {
          window.alert("Deleted");
          this.isDeleteDisabled = false;
          this.deleteSeviceDoctorModel = false;
          this.$router.go();
        })
        .catch((deleteServiceDoctorError) =>{
          this.isDeleteDisabled = false;
          this.deleteSeviceDoctorModel = false;
          handleError(deleteServiceDoctorError, this.$router, 'deleteServiceDoctorError', 'AdminLogin', 'token');
        });
      },
      changeAvailabilityStatus(service_doc_id,available_status) {
        this.isDisabled = true;
        this.isEditLoading = true;
        let editHighLightAvailRequest = {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          serviceDoctorId: service_doc_id,
          availabilityStatus: available_status ? 'AVAILABLE' : 'UNAVAILABLE'
        };
        axios_auth_instance_admin.post("/editServiceDoctorAvailability", editHighLightAvailRequest)
        .then(() => {
          this.isEditLoading = false;
          this.isDisabled = false;
        })
        .catch((editHighLightAvailError) => {
          this.isEditLoading = false;
          this.isDisabled = false;
          handleError(editHighLightAvailError, this.$router, 'editHighLightAvailError', 'AdminLogin', 'token');
        });
      }
    }
  }
  </script>
  
  <style scoped>
    .rowFlex {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .colFlex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 0px;
    }
  </style>
  <style>
  td,
  th {
    border: 0px;
  }
  td {
     padding: 2px;
    font-size: 8pt;
    font-weight: 700;
    text-align: left;
    padding-left: 5px;
  
  }
  th {
    background-color: #1467bf;
    color: white;
    border: 0px;
    font-size: 8pt;
    font-weight: 500;
    margin-left: 10%;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 40px;
    padding-left: 5px;
     text-align: left;
  }
  tr {
    border-bottom: #d4efff 1px solid !important;
  }
  .tableClass {
    border-spacing: 0px;
    width: 100%;
    border-collapse: collapse;
    z-index: 0;
    background-color: white;
    /* margin-left: 10px; */
  }
  table > tr > td:last-child {
    white-space: nowrap;
  }
  .btnClass {
    background-color: transparent;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 2  px !important;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  #facebook{
    display: none;
  }
  </style>